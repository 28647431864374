import Vue from 'vue';
import VueRouter from 'vue-router';

const configurator = () =>
  import(/* webpackChunkName: "configurator" */ '@/views/configurator/index.vue');
const configuratorForm = () =>
  import(/* webpackChunkName: "configurator" */ '@/views/configurator/configurator.vue');
const thankYou = () =>
  import(/* webpackChunkName: "configurator" */ '@/views/configurator/thankYou.vue');

const guideIndex = () => import(/* webpackChunkName: "guide" */ '@/views/guide/index.vue');
const journey = () => import(/* webpackChunkName: "guide" */ '@/views/guide/journey.vue');
const login = () => import(/* webpackChunkName: "guide" */ '@/views/guide/login.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: '/konfigurator', // '/:configuratorUID',
    props: true,
    component: configurator,
    meta: {
      layout: 'page',
    },
    children: [
      {
        path: '',
        component: configuratorForm,
        meta: {
          layout: 'page',
        },
      },
      {
        path: 'thank-you',
        name: 'thank-you',
        component: thankYou,
        meta: {
          layout: 'page',
        },
      },
    ],
  },
  {
    path: '/guide',
    props: true,
    component: guideIndex,
    meta: {
      layout: 'page',
    },
    children: [
      {
        path: '',
        component: login,
        name: 'login',
        meta: {
          layout: 'page',
        },
      },
      {
        path: ':journeyId',
        name: 'guide',
        props: (route) => ({
          step: route.query.step,
          journeyId: route.params.journeyId,
        }),
        component: journey,
        meta: {
          layout: 'page',
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: 'app',
  routes,
});

export default router;

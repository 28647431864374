const state = {
  journey: null,
  journeyId: '',
  loggedInUser: null,
  beer: null,
  intermediate: null,
  quiz: null,
  socialMedias: null,
  beerRating: {},
};

export { state as default };

<template>
  <div class="cookie-consent" v-if="!consentCookieExists">
    <p class="m-b-lg">
      {{ $t('legal.cookieNotice') }}
    </p>
    <button class="button button-accept" @click="acceptCookies">
      {{ $t('legal.acceptCookies') }}
    </button>
    <button class="button button-decline" @click="declineCookies">
      {{ $t('legal.declineCookies') }}
    </button>
  </div>
</template>
<script>
import { consents, hasBorlabsCookie, mergeArrays } from '@/utils/borlabsCookie';

export default {
  data() {
    return {
      consentCookieExists: this.checkCookie(),
    };
  },

  methods: {
    checkCookie() {
      if (!consents.essential.includes('conversion')) return false;
      return hasBorlabsCookie();
    },

    acceptCookies() {
      consents.add({
        essential: ['borlabs-cookie', 'conversion'],
        marketing: ['google-tag-manager'],
      });
      window.location.reload();
    },

    declineCookies() {
      consents.reset({
        essential: mergeArrays(consents.essential, ['borlabs-cookie', 'conversion']),
        marketing: [],
      });
      this.consentCookieExists = true;
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_variables';

.cookie-consent {
  z-index: 10;
  position: fixed;
  padding: 1rem;
  width: 100%;
  top: 0;
  background-color: $dark-grey;
  color: $white;
}

.button {
  padding: 1rem 0.5rem;
  border: 0;
  margin: 0.5rem 0;
  width: 100%;
  cursor: pointer;

  @media screen and (min-width: 32rem) {
    width: auto;
    margin: 0.5rem 1rem 0.5rem 0;
  }

  &-accept {
    background-color: #00d115;
    color: $dark-grey;
  }

  &-decline {
    background-color: $white;
    color: $dark-grey;
  }
}
</style>

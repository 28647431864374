import { configurators, customerTypes, eventTypes, products, extras, orders } from '@/agent';

const actions = {
  /**
   * Fetches configurator data based on the UID (e.g. 'bremen') from the Backend.
   * @param {*} param0
   * @param {*} param1
   */
  async fetchConfiguratorData({ commit }, { configuratorUID }) {
    const configuratorData = await configurators.get(configuratorUID);
    commit('setConfigurator', { configuratorData });
  },

  async fetchCustomerTypes({ commit } /* , { configuratorId } */) {
    const filteredCustomerTypes = await customerTypes.findByConfiguratorId(/* configuratorId */);
    commit('setCustomerTypes', { customerTypes: filteredCustomerTypes });
  },

  async fetchEventTypes({ commit }, { customerTypeId }) {
    const filteredEventTypes = await eventTypes.findByConfiguratorId(customerTypeId);
    commit('setEventTypes', { eventTypes: filteredEventTypes });
  },

  /**
   * Invokes agent method to retrieve all products assigned to a specific configurator and sets data
   * to store.
   * @param {Object} vuexMethods - all vuex methods
   * @param {String} configuratorId
   */
  async fetchProducts({ commit }, { customerTypeId, eventTypeId }) {
    const filteredProducts = await products.findByConfiguratorId(customerTypeId, eventTypeId);
    commit('setProducts', { products: filteredProducts });
  },

  async fetchExtras({ commit }, { productId }) {
    const filteredExtras = await extras.findByProductId(productId);
    commit('setExtras', { extras: filteredExtras });
  },

  setSelectedCustomerType({ commit }, { customerTypeId }) {
    commit('addCustomerTypeToOrder', { customerTypeId });
  },

  setSelectedEventType({ commit }, { eventTypeId }) {
    commit('addEventTypeToOrder', { eventTypeId });
  },

  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  setSelectedProduct({ commit }, { productId }) {
    commit('addProductToOrder', { productId });
  },

  removeSelectedProduct({ commit }) {
    commit('removeProductFromOrder');
  },

  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  setSelectedExtras({ commit }, { selectedExtras }) {
    commit('setExtrasToOrder', { selectedExtras });
  },

  removeSelectedExtras({ commit }) {
    commit('removeAllExtrasFromOrder');
  },

  setOrderDetails({ commit }, { formData }) {
    commit('addDetailsToOrder', { formData });
  },

  // TODO: add CustomerType and EventType
  async postOrder(
    { commit },
    {
      configuratorId,
      details,
      customerType,
      eventType,
      selectedProduct,
      selectedExtras,
      orderValue,
    },
  ) {
    await orders.post({
      configurator: [configuratorId],
      // personal data
      businessCustomer: null, // –
      FirstName: details.firstName,
      LastName: details.lastName,
      EmailAdress: details.emailAdress,
      PhoneNumber: details.phoneNumber,
      Company: details.company,
      Street: details.street,
      ZipCode: details.zipCode,
      City: details.city,
      PersonalMessage: details.personalMessage,
      // AgbConfirmation: details.agbConfirmation,
      // DSGVO: details.dsgvo,
      // is this order a request?
      Request: details.isRequest,
      // product & order data
      PeopleCount: details.peopleCount,
      PlayDateTime: `${details.playDate} ${details.playTime}`,
      // Order value
      OrderValue: orderValue,
      // selected Products
      customer_type: customerType,
      event_type: eventType,
      product: selectedProduct,
      extras: selectedExtras,
    });
    commit('removeDetailsFromOrder');
  },
};

export { actions as default };

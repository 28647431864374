import superagent from 'superagent';
import cookies from '@/utils/cookies';
import { getConversionCookie } from '@/utils/tracking';

const API_URL = process.env.VUE_APP_API_ROOT;

function handleError(err) {
  return err;
}

const requests = {
  /**
   * Used to perform fetches and invoke errors if something fails.
   * @param {String} url - Endpoint or custom URL
   * @param {Object} options
   * @param {Boolean} options.customUrl -  True for full URL instead of endpoint definition.
   */
  async get(url, { customUrl = false } = {}) {
    try {
      const res = await superagent.get(customUrl ? url : `${API_URL}${url}`);
      return res.body;
    } catch (err) {
      handleError(err);
      return null;
    }
  },

  async authGet(url, { customUrl = false } = {}) {
    const authToken = cookies.get('guidetoken') || '';

    try {
      const res = await superagent
        .get(customUrl ? url : `${API_URL}${url}`)
        .set('Authorization', `Bearer ${authToken}`);
      return res.body;
    } catch (err) {
      handleError(err);
      return null;
    }
  },

  async post(url, payload) {
    try {
      const res = await superagent.post(`${API_URL}${url}`).send(payload);
      return res.body;
    } catch (err) {
      handleError(err);
      return null;
    }
  },

  async authPost(url, payload) {
    const authToken = cookies.get('guidetoken') || '';

    try {
      const res = await superagent
        .post(`${API_URL}${url}`)
        .set('Authorization', `Bearer ${authToken}`)
        .send(payload);
      return res.body;
    } catch (err) {
      handleError(err);
      return null;
    }
  },

  async find(url, query) {
    try {
      const queryString = Object.keys(query)
        .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(query[k])}`)
        .join('&');
      const res = await superagent.get(`${API_URL}${url}`).query(queryString);
      return res.body;
    } catch (err) {
      handleError(err);
      return null;
    }
  },

  async authFind(url, query) {
    const authToken = cookies.get('guidetoken') || '';

    try {
      const queryString = Object.keys(query)
        .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(query[k])}`)
        .join('&');
      const res = await superagent
        .get(`${API_URL}${url}`)
        .set('Authorization', `Bearer ${authToken}`)
        .query(queryString);
      return res.body;
    } catch (err) {
      handleError(err);
      return null;
    }
  },
};

const configurators = {
  async get(configuratorId) {
    return requests.get(`/configurators/${configuratorId}`);
  },
};

// TODO: change to a get if we don't have a query
const customerTypes = {
  async findByConfiguratorId(/* configuratorId */) {
    return requests.find('/customer-types/', {
      /* 'configurators._id': configuratorId */
    });
  },
};

const eventTypes = {
  async findByConfiguratorId(/* configuratorId, */ customerTypeId) {
    return requests.find('/event-types/', {
      /* 'configurators._id': configuratorId */
      'customer_types._id': customerTypeId,
    });
  },
};

const products = {
  async findByConfiguratorId(customerTypeId, eventTypeId) {
    return requests.find('/products/', {
      Active: true,
      'customer_types._id': customerTypeId,
      'event_types._id': eventTypeId,
    });
  },
};

const extras = {
  async findByProductId(productId) {
    return requests.find('/extras/', {
      Active: true,
      'products._id': productId,
      _sort: 'Priority:ASC',
    });
  },
};

const orders = {
  async post(payload) {
    return requests.post('/orders/', {
      ...getConversionCookie(),
      ...payload,
    });
  },
};

const journey = {
  async findJourneyById(journeyId) {
    return requests.authGet(`/bier-guide-journeys/uid/${journeyId}`);
  },
  async findBeerById(beerId) {
    return requests.authGet(`/beers/${beerId}`);
  },
  async findIntermediateById(intermediateId) {
    return requests.authGet(`/intermediate-steps/${intermediateId}`);
  },
  async findQuizById(quizId) {
    return requests.authGet(`/quizzes/${quizId}`);
  },
  async login(payload) {
    return requests.post('/bier-guide-logins', {
      ...getConversionCookie(),
      ...payload,
    });
  },
  async findSocialMedias() {
    return requests.authGet('/social-medias');
  },
  async sendFeedback(payload) {
    return requests.authPost('/feedbacks', { ...payload });
  },
};

const beerRatings = {
  async post(payload) {
    return requests.authPost('/beer-ratings', { ...payload });
  },

  async getAverageRatingByBeerId(beerId) {
    return requests.authGet(`/beer-ratings/average/${beerId}`);
  },
};

export { configurators, customerTypes, beerRatings, eventTypes, products, extras, orders, journey };

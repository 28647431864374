<template>
  <div class="footer p-xl">
    <ul>
      <li v-if="imprintLink">
        <a :href="imprintLink" target="_blank">Impressum</a>
      </li>
      <li v-if="privacyLink">
        <a :href="privacyLink" target="_blank">Datenschutz</a>
      </li>
      <li v-if="agbLink">
        <a :href="agbLink" target="_blank">AGB</a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'app-footer',

  props: {
    imprintLink: {
      type: String,
      default: '',
    },
    privacyLink: {
      type: String,
      default: '',
    },
    agbLink: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  text-align: center;

  ul {
    display: flex;
    justify-content: center;
  }

  li {
    padding: 0.2rem 0.5rem;

    & + li {
      border-left: 1px solid white;
    }
  }

  a {
    display: block;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
  }
}
</style>

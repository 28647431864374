const mutations = {
  setLoggedInUser(state, { user }) {
    state.loggedInUser = user;
  },
  setJourney(state, { journey }) {
    state.journey = journey;
  },

  setBeer(state, { beer }) {
    state.beer = beer;
  },

  setIntermediate(state, { intermediate }) {
    state.intermediate = intermediate;
  },

  setQuiz(state, payload) {
    state.quiz = payload;
  },

  setSocialMedias(state, payload) {
    state.socialMedias = payload.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});
  },

  setAverageBeerRating(state, payload) {
    state.averageBeerRating = payload;
  },
};

export { mutations as default };

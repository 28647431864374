const state = {
  configuratorData: {
    PrimaryColor: '#000000',
    SecondaryColor: '#000000',
  },
  customerTypes: [],
  eventTypes: [],
  products: [],
  extras: [],
  order: {
    details: {},
    customerType: '',
    eventType: '',
    product: '',
    extras: [],
  },
};

export { state as default };

const getters = {
  getConfiguratorData(state) {
    return state.configuratorData;
  },

  getCustomerTypes(state) {
    return state.customerTypes;
  },

  getEventTypes(state) {
    return state.eventTypes;
  },

  getProducts(state) {
    return state.products;
  },

  getExtras(state) {
    return state.extras;
  },

  getOrder(state) {
    return state.order;
  },
};

export { getters as default };

<template>
  <theme-provider :theme="theme" class="wrapper">
    <app-header :logoUrl="theme.logoUrl" v-if="$route.meta.layout === 'page'" />
    <div class="app">
      <router-view />
    </div>
    <app-footer
      :imprintLink="configuratorData.ImprintLink"
      :privacyLink="configuratorData.PrivacyLink"
      :agbLink="configuratorData.TermsAndConditionsLink"
    />
    <cookie-consent />
  </theme-provider>
</template>

<script>
import { mapGetters } from 'vuex';
import { ThemeProvider } from 'vue-styled-components';
import AppHeader from '@/components/global/header.vue';
import AppFooter from '@/components/global/footer.vue';
import CookieConsent from '@/components/cookieConsent.vue';

export default {
  name: 'App',

  components: {
    AppHeader,
    ThemeProvider,
    AppFooter,
    CookieConsent,
  },

  computed: {
    ...mapGetters({
      configuratorData: 'configurators/getConfiguratorData',
      theme: 'general/getTheme',
    }),
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > .app {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}
</style>

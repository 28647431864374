const actions = {
  /**
   * Due we use the same codebase for all cities we need to make some color modifications in
   * some cases.
   * Theme providers in this repository should only use the theme provided from store.
   * @param {Object} commit - vuex commit method
   * @param {Object} theme - theme variables
   */
  setTheme({ commit }, changes) {
    commit('setTheme', changes);
  },
};

export { actions as default };

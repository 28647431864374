import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueGtm from 'vue-gtm';
import messages from './lang';
import App from './App.vue';
import router from './router';
import store from './store';
import { consents } from './utils/borlabsCookie';

import './scss/index.scss';

// icon library
import 'vue-awesome/icons/info';
import 'vue-awesome/icons/times';
import 'vue-awesome/icons/bars';
import 'vue-awesome/icons/beer';
import 'vue-awesome/icons/star';
import 'vue-awesome/icons/crown';
import 'vue-awesome/icons/check';

Vue.config.productionTip = false;

/**
 * i18n congiguration
 * https://kazupon.github.io/vue-i18n/
 */

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'de',
  messages,
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

/**
 * Init GTM
 */

Vue.use(VueGtm, {
  id: 'GTM-NDN5C4F',
  enabled: consents.marketing.includes('google-tag-manager'),
  debug: false,
  loadScript: true,
});

const cookies = {
  get(name) {
    if (typeof window !== 'undefined') {
      const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
      if (match) {
        return match[2];
      }
    }
    return false;
  },

  set(name, value) {
    const date = new Date();
    const days = 1;

    date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = `${name}=${value}; Path=/; expires=${date.toGMTString()}; SameSite=Strict`;
  },

  remove(name) {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  },
};

export { cookies as default };

import Cookies from 'vue-cookies';
import { consents } from '@/utils/borlabsCookie';

const CLIDS = ['gclid', 'fbclid', 'madid', 'msclkid'];

export function setConversionCookie(query) {
  if (consents.essential.includes('conversion')) {
    const src = CLIDS.find((id) => query[id]);
    if (src) {
      Cookies.set(src, { id: query[src], created: new Date().getTime() }, '30d');
    }
  }
}

export function getConversionCookie() {
  return {
    TrackingOrigin: CLIDS.map((src) => ({ src, ...Cookies.get(src) }))
      .filter((clid) => clid.id)
      .map((clid) => ({
        __component: 'tracking.tracking-origin',
        TrackingID: clid.id,
        TrackingSource: clid.src,
      })),
  };
}

const defaultTheme = {
  white: '#ffffff',
  lightGrey: '#E3E3E3',
  darkGrey: '#404040',
  darkerGrey: '#1c2125',

  defaultShadow: '2px 2px 15px 10px rgba(0,0,0,0.06)',
  defaultBorderRadius: '4px',

  cardBackground: '#ffffff',
  cardTextColor: 'inherit',
};

export { defaultTheme as default };

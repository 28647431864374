const getters = {
  getLoggedInUser(state) {
    return state.loggedInUser;
  },

  getJourney(state) {
    return state.journey;
  },

  getJourneyId(state) {
    return state.journey.JourneyNumber;
  },

  getJourneyBeers(state) {
    return state.journey.Steps.map((step) => step.Beer).filter(Boolean);
  },

  getBeer(state) {
    return state.beer;
  },

  getSteps(state) {
    return state.journey.Steps;
  },

  getIntermediate(state) {
    return state.intermediate;
  },

  getQuiz(state) {
    return state.quiz;
  },

  getSocialMedias(state) {
    return state.socialMedias;
  },

  getJourneyRatingSetting(state) {
    return state.journey.Rateable;
  },

  getAverageBeerRating(state) {
    return state.averageBeerRating;
  },
};

export { getters as default };

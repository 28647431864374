const mutations = {
  setTheme(state, changes) {
    state.theme = {
      ...state.theme,
      ...changes,
    };
  },
};

export { mutations as default };

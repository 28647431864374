import { journey, beerRatings } from '@/agent';

const actions = {
  async fetchJourney({ commit }, { journeyId }) {
    const journeyData = await journey.findJourneyById(journeyId);
    commit('setJourney', { journey: journeyData });
  },

  async fetchBeer({ commit }, beerId) {
    const beerData = await journey.findBeerById(beerId);
    commit('setBeer', { beer: beerData });
  },

  async fetchIntermediate({ commit }, intermediateId) {
    const intermediateData = await journey.findIntermediateById(intermediateId);
    commit('setIntermediate', { intermediate: intermediateData });
  },

  async fetchQuiz({ commit }, quizId) {
    const quizData = await journey.findQuizById(quizId);
    commit('setQuiz', quizData);
  },

  async fetchLogin({ commit }, loginPayload) {
    const login = await journey.login(loginPayload);
    commit('setLoggedInUser', { user: login });
  },

  async setLoggedInUser({ commit }, token) {
    commit('setLoggedInUser', { user: { jwt: token } });
  },

  async fetchSocialMedias({ commit }) {
    const data = await journey.findSocialMedias();
    commit('setSocialMedias', data);
  },

  async fetchFeedback(_, feedbackPayload) {
    return journey.sendFeedback(feedbackPayload);
  },

  async fetchAverageBeerRating({ commit }, { beerId }) {
    const data = await beerRatings.getAverageRatingByBeerId(beerId);
    commit('setAverageBeerRating', data);
  },

  async sendBeerRating(_, payload) {
    await beerRatings.post(payload);
  },
};

export { actions as default };

import Vue from 'vue';
import Vuex from 'vuex';
import general from './general/index';
import configurators from './configurators/index';
import guide from './guide/index';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    general: {
      ...general,
      namespaced: true,
    },
    configurators: {
      ...configurators,
      namespaced: true,
    },
    guide: {
      ...guide,
      namespaced: true,
    },
  },
});

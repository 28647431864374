const messages = {
  de: {
    legal: {
      cookieNotice:
        'Diese Website verwendet Cookies – nähere Informationen dazu finden Sie in unserer Datenschutzerklärung am Ende der Seite.',
      acceptCookies: 'Cookies akzeptieren und schließen',
      declineCookies: 'essentielle Cookies akzeptieren',
    },
    forms: {
      businessCustomerCheck: 'Ich buche dieses Event für ein Unternehmen.',
      firstName: 'Vorname',
      lastName: 'Nachname',
      email: 'E-Mail',
      phone: 'Telefon',
      company: 'Firma',
      city: 'Ort',
      zip: 'PLZ',
      zipTitle: 'Für Buchungen aus dem Ausland bitte die 99999 eingeben!',
      street: 'Straße, Nr.',
      message: 'Nachricht',
      agb: 'Ich akzeptiere die <a href="{link}" target="_blank">AGB</a>.',
      dsgvoMessage:
        'Ich stimme zu, dass zur Beantwortung meiner Anfrage meine Angaben aus dem Kontaktformular erhoben und verarbeitet werden dürfen. Hinweise: Nach abgeschlossener Bearbeitung der Anfrage werden die Daten gelöscht. Die Einwilligung kann jederzeit für die Zukunft per E-Mail an info@citygames.de widerrufen werden. Detaillierte Informationen zum Umgang mit Nutzerdaten finden Sie in unserer <a href="{link}" target="_blank">Datenschutzerklärung</a> *',
      peopleCount: 'Gruppengröße',
      playDate: 'Wunschdatum',
      playTime: 'Wunschuhrzeit',
      next: 'weiter',
      send: 'Senden',
      sendRequest: 'Angebot anfragen',
      bookNow: 'Jetzt buchen',
      requiredFields: 'Bitte fülle folgende Pflichtfelder aus:',
      persons: 'Personen',
      clock: 'Uhr',
    },
    configurator: {
      heading: {
        configurator: 'Event Konfigurator',
        booking: 'Event Buchung',
        inquiry: 'Event Anfrage',
      },
      choose: 'Auswählen',
      back: 'Zurück',
      chooseCustomerType: 'KundenTYP',
      chooseCustomerTypeHeading: 'Angebote für',
      chooseCustomerTypeLong: 'Bitte wählen Sie einen Kundentyp aus:',
      chooseEventType: 'EventTYP',
      chooseEventTypeLong: 'Bitte wählen Sie Ihren EventTYP aus:',
      chooseGroupData: 'Teilnehmer',
      chooseGroupDataLong: 'Wann und mit wie vielen Teilnehmern planen Sie Ihr Event:',
      chooseProduct: 'Produkt',
      chooseProductLong: 'Bitte wählen Sie Ihr Produkt aus:',
      packageContent: 'Paketinhalt',
      extras: 'Extras',
      chooseExtras: 'Bitte wählen Sie ihre Extras aus:',
      nextWithoutExtras: 'Hier gehts weiter OHNE Extras',
      readMore: 'mehr lesen...',
      checkoutEmojis: '🍺🍺🍺',
      checkoutMessage: '👊 Fast geschafft. Nur noch...',
      perPerson: 'pro Person',
      perPersonShort: 'p.P.',
      flatRate: 'pauschal',
      summary: 'Zusammenfassung der Anfrage',
      thankYou: 'Herzlichen Dank für Deine Anfrage',
      thankYouText:
        'Wir werden Dein Anliegen schnellstmöglich bearbeiten und uns anschließend bei Dir melden. \n Vielen Dank und bis bald! Dein BIEREVENTS-Team',
      groupSize: 'Gruppengröße',
      dateAndTime: 'Datum & Uhrzeit',
      pricePerPerson: 'Preis pro Person:',
      shipping: 'exkl. {shippingCosts} Versand',
      total: 'Gesamtpreis:',
      tax: 'inkl. {tax} Umsatzsteuer',
      request: 'Ich möchte lieber erst einmal eine unverbindliche Anfrage senden.',
    },
    guide: {
      forms: {
        login: {
          heading: 'Login',
          subheading: 'Jetzt einloggen und Biertasting starten',
          journey: 'Benutzer-ID',
          password: 'Passwort',
          email: 'E-Mail',
          agb: 'Ich haben die <a href="{agbLink}" target="_blank">AGBs</a> und die <a href="{privacyLink}" target="_blank">Datenschutzerklärung</a> gelesen und akzeptiere sie.',
          submit: 'Login',
        },
      },
      buttons: {
        prevStep: 'Zurück',
        nextStep: 'Weiter',
        nextBeer: 'Zum nächsten Bier',
        firstBeer: 'Zum ersten Bier',
        beerInfos: 'Infos zum Bier',
        breweryInfos: 'Infos zur Brauerei',
        closePopup: 'Close',
        sendAnswer: 'Antwort senden',
        quiz: 'Weiter zum Quiz',
        skipQuiz: 'Quiz überspringen',
        summary: 'Zur Zusammenfassung',
        feedback: 'Feedback absenden',
        startJourney: 'Hier geht es los',
        resumeJourney: 'Journey fortsetzen',
      },
      tooltips: {
        rateFirst: 'Bitte bewerte noch das Bier',
        fillAll: 'Bitte alle Felder ausfüllen',
      },
      hints: {
        rateFirst: '(Erst nach deiner Bewertung wird dein nächster Schritt freigeschaltet ;-))',
      },
      messages: {
        feedback: {
          success: 'Feedback abgesendet',
          failure: 'Fehler aufgetreten, erneut absenden',
        },
      },
      didYouLikeIt: 'Wie hat dir das Bier geschmeckt?',
      yourTopBeers: 'Deine TOP-Biere',
      feedback: 'Du hast Feedback für uns? Wir freuen uns auf deine Nachricht!',
      quiz: {
        correct: 'Korrekt',
        wrong: 'Falsch',
      },
      beer: {
        brewery: 'Brauerei',
        alcoholLevel: 'Alkoholgehalt',
        ibuLevel: 'IBU-Wert',
        beerStyle: 'Bierstil',
        goesWellWith: 'Passt gut zu',
        optic: 'Optik',
        flavorAroma: 'Aroma & Geruch',
        mouthfeel: 'Mundgefühl',
        firstSip: 'Antrunk',
        taste: 'Geschmack',
        afterTaste: 'Abgang',
        presentation: 'Präsentation',
        presentationTooltip: 'Skala: von "Nicht ansprechend" bis "Sehr ansprechend"',
        contemporary: 'Zeitgemäß',
        contemporaryTooltip: 'Skala: von "Nicht zeitgemäß" bis "Sehr zeitgemäß"',
        smellIntensity: 'Geruchsintensität',
        smellIntensityTooltip: 'Skala: von "Sehr leicht" bis "Sehr Stark"',
        acerbity: 'Herbheit',
        acerbityTooltip: 'Skala: von "Nicht herb" bis "Sehr herb"',
        fruitness: 'Fruchtigkeit',
        fruitnessTooltip: 'Skala: von "Sehr wenig" bis "Sehr stark"',
        wort: 'Würze',
        wortTooltip: 'Skala: von "Sehr wenig" bis "Sehr stark"',
        firstSipAroma: 'Aromen beim Antrunk',
        firstSipAromaTooltip: 'Skala: von "Sehr wenig" bis "Intensiv"',
        afterSipAroma: 'Aromen beim Abgang',
        afterSipAromaTooltip: 'Skala: von "Sehr wenig" bis "Intensiv"',
        averageRatingInfo: '{numOfRatings} andere Bierliebhaber haben im Durchschnitt {points} Punkte vergeben.',
        categoryLook: 'Anschauen',
        categorySmell: 'Riechen',
        categoryTaste: 'Schmecken',
        categoryMouthfeel: 'Mundgefühl',
        rating: 'Bewertung',
      },
    },
  },
};

export { messages as default };

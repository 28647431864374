import Cookies from 'vue-cookies';

const consentCookieName = 'borlabs-cookie';

function getBorlabsCookie() {
  return { consents: {}, ...Cookies.get(consentCookieName) };
}

function setBorlabsCookie(cookieValue) {
  Cookies.set(consentCookieName, cookieValue, '1y');
}

export function mergeArrays(array1, array2) {
  return (array1 || [])
    .concat(array2 || [])
    .filter((value, index, self) => self.indexOf(value) === index);
}

export function hasBorlabsCookie() {
  return Cookies.isKey(consentCookieName);
}

export const consents = {
  get essential() {
    return getBorlabsCookie().consents.essential || [];
  },

  get statistics() {
    return getBorlabsCookie().consents.statistics || [];
  },

  get marketing() {
    return getBorlabsCookie().consents.marketing || [];
  },

  get trackingAllowed() {
    const cookie = getBorlabsCookie();
    return (cookie.consents.essential || []).includes('conversion');
  },

  add(data) {
    const cookie = getBorlabsCookie();
    Object.keys(data).forEach((key) => {
      cookie.consents[key] = mergeArrays(cookie.consents[key], data[key]);
    });
    setBorlabsCookie(cookie);
  },

  reset(data) {
    setBorlabsCookie({ consents: data });
  },
};

const mutations = {
  setConfigurator(state, { configuratorData }) {
    state.configuratorData = configuratorData;
  },

  setCustomerTypes(state, { customerTypes }) {
    state.customerTypes = customerTypes;
  },

  setEventTypes(state, { eventTypes }) {
    state.eventTypes = eventTypes;
  },

  setProducts(state, { products }) {
    state.products = products;
  },

  setExtras(state, { extras }) {
    state.extras = extras;
  },

  setOrderMessage(state, { message }) {
    return message;
  },

  /**
   * Adds selected product to order object.
   * @param {Object} state
   * @param {Object} payload
   * @param {Array} payload.customerTypeId
   */
  addCustomerTypeToOrder(state, { customerTypeId }) {
    state.order.customerType = customerTypeId;
  },

  /**
   * Adds selected product to order object.
   * @param {Object} state
   * @param {Object} payload
   * @param {Array} payload.eventTypeId
   */
  addEventTypeToOrder(state, { eventTypeId }) {
    state.order.eventType = eventTypeId;
  },

  /**
   * Adds selected product to order object.
   * @param {Object} state
   * @param {Object} payload
   * @param {Array} payload.productId
   */
  addProductToOrder(state, { productId }) {
    state.order.product = productId;
  },

  removeProductFromOrder(state) {
    state.order.product = '';
  },

  /**
   *
   * @param {Object} state
   * @param {Object} payload
   * @param {Array} payload.selectedExtras
   */
  setExtrasToOrder(state, { selectedExtras }) {
    state.order.extras = selectedExtras;
  },

  removeAllExtrasFromOrder(state) {
    state.order.extras = [];
  },

  addDetailsToOrder(state, { formData }) {
    state.order.details = { ...state.order.details, ...formData };
  },

  removeDetailsFromOrder(state) {
    state.order.details = {};
  },
};

export { mutations as default };

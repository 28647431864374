<template>
  <header>
    <img alt="logo" :src="logoUrl" class="logo" />
  </header>
</template>
<script>

export default {
  name: 'app-header',

  props: {
    logoUrl: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>

$header_breakpoint: 60rem;

.logo {
  margin: 0.5rem;
  max-height: 5rem;
  @media screen and (min-width: $header_breakpoint) {
    max-height: 10rem;
  }
}
</style>
